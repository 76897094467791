import { EmailConversation, EmailThread } from 'types/activities/email';
import { PaginationProps } from 'types/common/pagination';
import { DateRangeSearchParams, PaginationSearchParams } from '../common';

export enum InboxEmailConversationType {
  UNREAD = 'UNREAD',
  ALL = 'ALL',
  INCOMING = 'INCOMING',
}

export enum InboxEmailMode {
  MY_INBOX = 'MY_INBOX',
  AR_INBOX = 'AR_INBOX',
}

export enum InboxEmailSearchParam {
  page = 'page',
  pageSize = 'page_size',
  emailType = 'status',
  emailMode = 'email_mode',
  fromDate = 'from_date',
  toDate = 'to_date',
  threadId = 'thread_id',
  dateRange = 'date_range',
}

export interface EmailThreadSearchParams extends DateRangeSearchParams, PaginationSearchParams {
  [InboxEmailSearchParam.emailType]: InboxEmailConversationType;
  [InboxEmailSearchParam.threadId]: number;
  [InboxEmailSearchParam.emailMode]: InboxEmailMode;
}

export interface EmailThreadResponse {
  parent_email: EmailConversation;
  replies: EmailConversation[];
}

export interface EmailThreadsList extends PaginationProps {
  data: EmailThread[];
}

export interface UnReadCountResponse {
  unread_count: number;
}
