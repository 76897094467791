import { Radio } from 'antd';
import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { concat, map } from 'lodash';
import DocumentsTypeSelect from '../FormElements/DocumentTypes';
import InvoiceSelect from '../FormElements/InvoiceSelect';
import UploadFormFiles from '../FormElements/UploadFormFiles';
import { documentOptions } from '../predicate';
import { ActivityFormItem, DocumentInvoiceSelect } from '../style';
import { DocumentFormElementProps, DocumentFormModalProps } from '../types';

export default function DocumentFormItems<DocumentElements extends DocumentFormElementProps>(
  props: Omit<DocumentFormModalProps<DocumentElements>, 'openModal'>
) {
  const {
    form,
    entityLevel,
    documentQueryFn,
    documentQueryKey,
    invoices,
    invoiceQueryFn,
    invoiceSearchFn,
    invoiceQueryKey,
    invoiceTransformSchema,
    text,
  } = props;

  const {
    documentTypeLabel,
    documentTypePlaceholder,
    invoiceLabel,
    invoicePlaceholder,
    descriptionLabel,
    descriptionPlaceholder,
    documentLevelLabel,
  } = text;

  const documentLevel = useFormWatch('documentLevelType', form) ?? entityLevel;
  const documentMapping = useFormWatch('documentMapping', form) ?? 'ALL';
  const fileList: File[] | undefined = useFormWatch('documentFileParams', form);
  const selectedInvoiceIds = map(invoices, (item) => (item as { id?: number })?.id).filter(Boolean);

  const DocumentTypeLevel = (
    <ActivityFormItem
      name="documentLevelType"
      label={documentLevelLabel}
      initialValue={entityLevel}
    >
      <Radio.Group options={documentOptions} optionType="button" />
    </ActivityFormItem>
  );

  function onChangeInvoiceList(invoiceList: number[]) {
    form.setFieldValue('documentInvoices', invoiceList);
  }

  const DocumentTypeList = (
    <ActivityFormItem
      name="documentType"
      label={documentTypeLabel}
      rules={[
        {
          required: true,
          message: `${documentTypeLabel} is required`,
        },
      ]}
      required
      preserve={false}
    >
      <DocumentsTypeSelect
        placeholder={documentTypePlaceholder}
        queryFn={documentQueryFn}
        queryKey={concat(documentQueryKey, documentLevel)}
        onChangeDocumentItems={(value) => form.setFieldValue('documentType', value)}
        entityLevel={documentLevel}
        key={documentLevel}
      />
    </ActivityFormItem>
  );

  const AssociatedInvoice = (
    <StyledFormItem
      name="documentMapping"
      style={{ marginBottom: documentMapping === 'SELECTIVE' ? 'var(--space-24)' : 0 }}
    >
      <Radio.Group defaultValue={'ALL'}>
        <Flex direction="column" gap={'var(--space-12)'}>
          <Radio value={'ALL'}>All Open Invoices</Radio>
          <Radio value={'SELECTIVE'}>Invoice</Radio>
        </Flex>
      </Radio.Group>
    </StyledFormItem>
  );

  const InvoiceSelectList = (
    <StyledFormItem
      label={invoiceLabel}
      name={'documentInvoices'}
      initialValue={selectedInvoiceIds}
      rules={[
        {
          required: true,
          message: `${invoiceLabel} is required`,
        },
      ]}
      required
    >
      <InvoiceSelect<DocumentElements['invoiceSelect']>
        placeholder={invoicePlaceholder}
        selectedInvoicesId={selectedInvoiceIds as number[]}
        selectedInvoices={invoices}
        onChangeInvoice={onChangeInvoiceList}
        queryFn={invoiceQueryFn}
        searchQueryFn={invoiceSearchFn}
        queryKey={invoiceQueryKey}
        transformSchema={invoiceTransformSchema}
      />
    </StyledFormItem>
  );

  const DocumentDescription = (
    <ActivityFormItem label={descriptionLabel} name="documentDescription">
      <DescriptionTextArea placeholder={descriptionPlaceholder} />
    </ActivityFormItem>
  );

  const UploadDisputeFile = (
    <UploadFormFiles
      form={form}
      formFieldName={'documentFileParams'}
      selectedValues={fileList}
      required
      onChange={(info) => {
        props.handleDisable(info.file.status === 'uploading');
      }}
    />
  );

  const InvoicesDocumentSelect = (
    <Flex style={{ marginBottom: 'var(--space-24)', width: '100%' }}>
      {documentLevel === ActionableEntity.INVOICE ? (
        <Flex direction="column" gap={'var(--space-8)'} style={{ width: '100%' }}>
          <GrowText>Associated Invoices</GrowText>
          <DocumentInvoiceSelect>
            {AssociatedInvoice}
            {documentMapping === 'SELECTIVE' ? (
              <div
                className={`${
                  documentMapping === 'SELECTIVE' ? 'active-transition' : 'hide-transition'
                }`}
              >
                {InvoiceSelectList}
              </div>
            ) : null}
          </DocumentInvoiceSelect>
        </Flex>
      ) : null}
    </Flex>
  );

  return (
    <>
      {DocumentTypeLevel}
      {DocumentTypeList}
      {DocumentDescription}
      {InvoicesDocumentSelect}
      {UploadDisputeFile}
    </>
  );
}
