import { Menu, Typography } from 'antd';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { formattingOptions } from 'lib/RichText';
import { ReactChild, useCallback, useEffect } from 'react';
import { commonButtonProps } from '../../Toolbar';
import { DropdownButton } from '../../common/DropdownWrapper';
import { ToolbarOptionProps } from '../../type';

const { Text } = Typography;

const t = {
  label: 'Font',
  sansSerif: 'Sans Serif',
  serif: 'Serif',
  fixedWidth: 'Fixed Width',
  narrow: 'Narrow',
  georgia: 'Georgia',
  tahoma: 'Tahoma',
  trebuchetMS: 'Trebuchet MS',
  verdana: 'Verdana',
};

const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
  title: t.label,
};

type Key = Parameters<NonNullable<React.ComponentProps<typeof Menu>['onSelect']>>[0];

type FontFamilyType =
  | 'sans-serif'
  | 'Serif'
  | 'monospace'
  | 'arial narrow'
  | 'georgia'
  | 'Tahoma'
  | 'Trebuchet MS'
  | 'Verdana';

interface OptionItem {
  label: ReactChild;
  key: FontFamilyType;
}

const defaultFontFamily = 'sans-serif';

const options: OptionItem[] = [
  {
    label: <span style={{ fontFamily: 'sans-serif !important' }}>{t.sansSerif}</span>,
    key: 'sans-serif',
  },
  {
    label: <span style={{ fontFamily: 'serif !important' }}>{t.serif}</span>,
    key: 'Serif',
  },
  {
    label: <span style={{ fontFamily: 'Courier !important' }}>{t.fixedWidth}</span>,
    key: 'monospace',
  },
  {
    label: <span style={{ fontFamily: 'arial narrow !important' }}>{t.narrow}</span>,
    key: 'arial narrow',
  },
  {
    label: <span style={{ fontFamily: 'georgia !important' }}>{t.georgia}</span>,
    key: 'georgia',
  },
  {
    label: <span style={{ fontFamily: 'Tahoma !important' }}>{t.tahoma}</span>,
    key: 'Tahoma',
  },
  {
    label: <span style={{ fontFamily: 'Trebuchet MS !important' }}>{t.trebuchetMS}</span>,
    key: 'Trebuchet MS',
  },
  {
    label: <span style={{ fontFamily: 'Verdana !important' }}>{t.verdana}</span>,
    key: 'Verdana',
  },
];

interface FontFamilyProps extends ToolbarOptionProps {}
export function FontFamily({ editor }: FontFamilyProps) {
  const editorFontFamily = editor.getAttributes('textStyle')['fontFamily'] ?? defaultFontFamily;
  const currentFontSelection = options.find((font) => font.key === editorFontFamily)?.label;

  const updateFontFamily = useCallback(
    (fontFamily: FontFamilyType) => {
      formattingOptions.fontFamily.onClick(editor, fontFamily);
    },
    [editor]
  );

  useEffect(() => {
    if (editorFontFamily) {
      if (currentFontSelection !== editorFontFamily) updateFontFamily(editorFontFamily);
    } else {
      updateFontFamily(defaultFontFamily);
    }
  }, [currentFontSelection, editorFontFamily, updateFontFamily]);

  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    style: {
      width: 'auto',
    },
    icon: (
      <Text ellipsis style={{ width: '80px' }}>
        {currentFontSelection}
      </Text>
    ),
  };

  const CurrentFontSelection = <Button buttonProps={buttonProps} toolTipProps={toolTipProps} />;

  function onSelectionCallback(selection: Key) {
    updateFontFamily(selection.key as unknown as FontFamilyType);
  }


  return (
    <DropdownButton
      menu={{
        items: [...options],
        selectable: true,
        onSelect: onSelectionCallback,
        selectedKeys: [editorFontFamily],
      }}
    >
      {CurrentFontSelection}
    </DropdownButton>
  );
}
