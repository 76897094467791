import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HideWrapper } from '@sinecycle/growcomponents';
import { Button } from 'antd';
import useOpen from 'util/hooks/useOpen';
import { ActivityButtons, DocumentFormElementProps, DocumentFormModalProps } from '../types';
import DocumentFormModal from './DocumentFormModal';

export function getDocumentConfig(disabled?: boolean, buttonConfig?: ActivityButtons) {
  const iconColor = disabled
    ? undefined
    : buttonConfig?.color
    ? buttonConfig?.color
    : 'var(--primary-7)';

  const icon = <FontAwesomeIcon icon={['far', 'file-arrow-up']} color={iconColor} />;
  return { icon, text: 'Add Document' };
}

export default function DocumentButton<DocumentElements extends DocumentFormElementProps>(
  props: Omit<
    DocumentFormModalProps<DocumentElements>,
    'openModal' | 'form' | 'onClose' | 'onFormClose' | 'handleDisable'
  >
) {
  const { buttonConfig, disabled, mode = 'BUTTON' } = props;
  const { open, toggleOpen, onClose } = useOpen({ open: props.open ?? false });
  const config = getDocumentConfig(disabled, buttonConfig);
  function handleClose() {
    onClose();
    props.handleClose?.();
  }

  return (
    <>
      <HideWrapper hide={mode === 'LIST'}>
        <Button
          icon={!buttonConfig?.hideIcon ? config.icon : null}
          onClick={toggleOpen}
          disabled={disabled}
          {...buttonConfig}
        >
          {!buttonConfig?.hidetext && (buttonConfig?.text || config.text)}
        </Button>
      </HideWrapper>

      <DocumentFormModal<DocumentElements> openModal={open} onClose={handleClose} {...props} />
    </>
  );
}
