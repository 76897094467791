import { SearchParamsOption } from 'ky';
import { IActivityItem, IEmailActivity } from 'store/activity-feed/type';
import { DateRangeSearchParams } from 'types/api/common';
import {
  AssignedActivities,
  InboxActivitiesFilters,
  InboxActivityQueryParam,
  SubscribedActivities,
} from 'types/api/inbox/activity';
import {
  EmailThreadResponse,
  EmailThreadSearchParams,
  EmailThreadsList,
  InboxEmailMode,
  UnReadCountResponse,
} from 'types/api/inbox/email';
import { PageList } from 'types/common/page-list';
import ky from './ky';

const baseUrl = 'inbox';

interface UnreadCountResponse {
  unread_count: number;
}

////////////////////////////////////////////          EMAIL          ////////////////////////////////////////////

export async function fetchEmailThread(threadId?: number) {
  return await ky.get(`${baseUrl}/email-threads/${threadId}`).json<EmailThreadResponse>();
}

export async function fetchEmailThreads(searchParams?: Partial<EmailThreadSearchParams>) {
  return await ky
    .get(`${baseUrl}/email-threads`, { searchParams: searchParams as SearchParamsOption })
    .json<EmailThreadsList>();
}
export async function fetchUnreadCountEmailThreads(
  searchParams?: Partial<EmailThreadSearchParams>
) {
  return await ky
    .get(`${baseUrl}/email-threads/unread-count`, {
      searchParams,
    })
    .json<UnReadCountResponse>();
}

export async function fetchAREmailThreads(searchParams?: Partial<EmailThreadSearchParams>) {
  return await ky
    .get(`${baseUrl}/ar-email-threads`, { searchParams: searchParams as SearchParamsOption })
    .json<EmailThreadsList>();
}

export async function fetchAREmailThreadsUnread(searchParams?: Partial<EmailThreadSearchParams>) {
  return await ky
    .get(`${baseUrl}/ar-email-threads/unread-count`, {
      searchParams,
    })
    .json<UnReadCountResponse>();
}

export async function markEmailAsRead(thread_ids: number[]) {
  return await ky.post(`${baseUrl}/email-threads/mark-read`, { json: { thread_ids } });
}

export async function markEmailAsUnRead(thread_ids: number[]) {
  return await ky.post(`${baseUrl}/email-threads/mark-unread`, { json: { thread_ids } });
}

export async function fetchUnreadEmailsCount({
  type,
  searchParams,
}: {
  type: InboxEmailMode;
  searchParams: DateRangeSearchParams;
}) {
  const endpoint = type === InboxEmailMode.AR_INBOX ? 'ar-email-threads' : 'email-threads';

  return await ky
    .get(`${baseUrl}/${endpoint}/unread-count`, {
      searchParams: { ...searchParams } as SearchParamsOption,
    })
    .json<UnreadCountResponse>();
}

////////////////////////////////////////////   ACTIVITIES   ////////////////////////////////////////////
export async function markActivityAsRead(thread_ids: number[]) {
  return await ky.post(`${baseUrl}/activities/mark-read`, { json: { thread_ids } });
}

export async function markActivityAsUnread(thread_ids: number[]) {
  return await ky.post(`${baseUrl}/activities/mark-unread`, { json: { thread_ids } });
}

////////////////////////////////////////////   ASSIGNED ACTIVITIES   ////////////////////////////////////////////

export async function fetchAssignedActivities(
  filters?: InboxActivitiesFilters,
  searchParams?: InboxActivityQueryParam
) {
  return await ky
    .post(`${baseUrl}/activities/assigned`, {
      json: filters,
      searchParams,
    })
    .json<AssignedActivities>();
}

export async function fetchUnreadAssignedActivitiesCount() {
  return await ky.get(`${baseUrl}/activities/assigned/unread-count`).json<UnreadCountResponse>();
}

//////////////////////////////////////////// PARTICIPATING ACTIVITIES ////////////////////////////////////////////

export async function fetchSubscribedActivities(
  filters?: InboxActivitiesFilters,
  searchParams?: InboxActivityQueryParam
) {
  return await ky
    .post(`${baseUrl}/activities/subscribed`, {
      json: filters,
      searchParams,
    })
    .json<SubscribedActivities>();
}

export async function fetchUnreadSubscribedActivitiesCount() {
  return await ky.get(`${baseUrl}/activities/subscribed/unread-count`).json<UnreadCountResponse>();
}

export async function getEmailActivityFeed(
  type: string,
  activityType: string | undefined,
  page: number,
  id?: number,
  size?: number
): Promise<PageList<IActivityItem<IEmailActivity, IEmailActivity>, unknown>> {
  const url = `feeds/${type}/${id}`;

  const searchParams = {
    page: page ?? 1,
    size: size ?? 10,
    ...(activityType && { type: activityType }),
  };

  return await ky.get(url, { searchParams }).json();
}
