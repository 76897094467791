import { Form, InputNumber } from 'antd';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex, FlexChildProps } from 'components/BaseComponents/Layout/Flex';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { useRangeValuesAndValidation } from 'components/InvoiceSegment/hooks';
import { isNull } from 'lodash';
import { useCallback, useState } from 'react';
import { CustomFieldDataType, DecimalFields, validDecimalTypes } from 'types/entities/custom-field';
import { Range } from 'types/entities/invoice-segments';
import { DayRangeValueType } from '.';

interface CustomRangeProps<T> {
  value?: T;
  onChange?: (value: T) => void;
  currencySymbol?: string;
  dataType?: CustomFieldDataType;
}

export function dispatchErrorValidation(value: boolean) {
  const customEvent = new CustomEvent('RANGE_ERROR', {
    detail: { error: value },
  });
  document.dispatchEvent(customEvent);
}
function CustomRange<T extends DayRangeValueType>(props: CustomRangeProps<T>) {
  const { value, onChange } = props;
  const [range, setRange] = useState<Range>(initializeBetweenValue);
  function initializeBetweenValue(): Range {
    const from = value?.start;
    const to = value?.end;
    return {
      from: {
        value: Number(from),
      },
      to: {
        value: Number(to),
      },
    };
  }

  function getAlignSelf(): FlexChildProps['alignSelf'] {
    if (range.from.validateStatus === 'error' || range.to.validateStatus === 'error') {
      return 'flex-start';
    }
    return 'center';
  }
  const alignSelf = getAlignSelf();

  const { formValidation, toValidation } = useRangeValuesAndValidation({
    allowDecimal: validDecimalTypes.includes(props.dataType as DecimalFields),
  });
  function handleUpdateFromValue(fromValue: number) {
    onChange?.({
      start: String(fromValue),
      end: String(range.to.value),
      type: 'IN_BETWEEN',
    } as unknown as T);
  }
  function handleUpdateToValue(toValue: number) {
    onChange?.({
      start: String(range.from.value),
      end: String(toValue),
      type: 'IN_BETWEEN',
    } as unknown as T);
  }
  const updateFromValue = useCallback(handleUpdateFromValue, [onChange, range.to.value]);
  const updateToValue = useCallback(handleUpdateToValue, [onChange, range.from.value]);

  function handleFromChange(value: number | null) {
    const validationStatus = formValidation(value, range);
    setRange((previousValue) => {
      return {
        from: { ...validationStatus, value },
        to:
          validationStatus.validateStatus === 'success'
            ? { ...previousValue.to, errorMsg: null, validateStatus: 'success' }
            : { ...previousValue.to },
      };
    });
    if (validationStatus.validateStatus === 'error') {
      dispatchErrorValidation(true);
    }

    if (validationStatus.validateStatus === 'success' && !isNull(value)) {
      updateFromValue(value);
      dispatchErrorValidation(false);
    }
  }
  function handleToChange(value: number | null) {
    const validationStatus = toValidation(value, range);
    setRange((previousValue) => {
      return {
        from:
          validationStatus.validateStatus === 'success'
            ? { ...previousValue.from, errorMsg: null, validateStatus: 'success' }
            : { ...previousValue.from },
        to: { ...validationStatus, value },
      };
    });

    if (validationStatus.validateStatus === 'error') {
      dispatchErrorValidation(true);
    }

    if (validationStatus.validateStatus === 'success' && !isNull(value)) {
      updateToValue(value);
      dispatchErrorValidation(false);
    }
  }
  return (
    <Form
      layout="horizontal"
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 'var(--space-8)',
      }}
    >
      <StyledFormItem
        shouldUpdate
        validateStatus={range.from.validateStatus}
        help={range.from.errorMsg}
        className="from-value"
        initialValue={range.from.value}
        name="from"
        style={{ width: '90px' }}
      >
        <InputNumber
          onChange={handleFromChange}
          controls={false}
          placeholder="From"
          prefix={props.currencySymbol}
          style={{ width: '90px' }}
        />
      </StyledFormItem>
      <Flex.Child alignSelf={alignSelf}>
        <TypographyText>and</TypographyText>
      </Flex.Child>
      <StyledFormItem
        shouldUpdate
        validateStatus={range.to.validateStatus}
        help={range.to.errorMsg}
        className="to-value"
        initialValue={range.to.value}
        name="to"
        style={{ width: '90px' }}
      >
        <InputNumber
          onChange={handleToChange}
          placeholder="To"
          controls={false}
          prefix={props.currencySymbol}
          style={{ width: '90px' }}
        />
      </StyledFormItem>
    </Form>
  );
}

export default CustomRange;
