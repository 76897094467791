import { sanitizeSubject } from 'components/BaseComponents/RTE/util/sanitizeSubject';
import { deSerializeContacts } from 'components/Common/Contact';
import { omit } from 'lodash';
import { Attachments, ISendEmailParams } from 'store/email/types';
import { Emailable } from 'types/activities/email';
import { cleanArrayPredicate } from 'util/predicates';
import ky from './ky';

type EmailResponse = {
  subject: string;
  body: string;
};
const baseUrl = 'emails';

export async function getEmailPreview(subject: string, body: string) {
  return (await ky
    .post(`${baseUrl}/preview/templates`, {
      json: { subject: sanitizeSubject(subject), body },
    })
    .json()) as EmailResponse;
}

export const sendEmail = async (params: ISendEmailParams): Promise<unknown> => {
  const copy = { ...params };
  const filteredParams = omit(params, [
    'attach_all_open_invoices',
    'attach_current_invoice',
    'generative_attachments',
    'file_upload_ids',
    'customer_document_ids',
    'customer_document_type_ids',
    'invoice_document_ids',
    'invoice_document_type_ids',
  ]);

  const Attachments: Attachments = [
    {
      type: copy.attach_all_open_invoices
        ? 'ALL_OPEN_INVOICES'
        : copy.attach_current_invoice
        ? 'CURRENT_INVOICE'
        : undefined,
    },
    {
      type: copy.generative_attachments?.length ? 'GENERATIVE_ATTACHMENT' : undefined,
      list: copy.generative_attachments?.length ? copy.generative_attachments : undefined,
    },
    {
      type: copy.file_upload_ids?.length ? 'FILE_UPLOADS' : undefined,
      list: copy.file_upload_ids?.length ? copy.file_upload_ids : undefined,
    },
    {
      type: copy.attachments?.length ? 'ATTACHMENT' : undefined,
      list: copy.attachments?.length ? copy.attachments : undefined,
    },
    {
      type: copy.customer_document_ids?.filter(cleanArrayPredicate)?.length
        ? 'DOCUMENT'
        : copy.customer_document_type_ids?.filter(cleanArrayPredicate).length
        ? 'DOCUMENT'
        : copy.invoice_document_ids?.filter(cleanArrayPredicate).length
        ? 'DOCUMENT'
        : copy.invoice_document_type_ids?.filter(cleanArrayPredicate).length
        ? 'DOCUMENT'
        : undefined,
      customer_document_ids: copy.customer_document_ids?.filter(cleanArrayPredicate).length
        ? copy.customer_document_ids
        : undefined,
      invoice_document_ids: copy.invoice_document_ids?.filter(cleanArrayPredicate).length
        ? copy.invoice_document_ids
        : undefined,
      customer_document_type_ids: copy.customer_document_type_ids?.filter(cleanArrayPredicate)
        .length
        ? copy.customer_document_type_ids
        : undefined,
      invoice_document_type_ids: copy.invoice_document_type_ids?.filter(cleanArrayPredicate).length
        ? copy.invoice_document_type_ids
        : undefined,
    },
  ];

  const modifiedParam = {
    ...filteredParams,
    to: deSerializeContacts(params.to),
    cc: params.cc ? deSerializeContacts(params.cc) : [],
    bcc: params.bcc ? deSerializeContacts(params.bcc) : [],
    subject: sanitizeSubject(params.subject),
    attachments: Attachments.filter((element) => Boolean(element.type)),
  };
  return await ky.post(`${baseUrl}/send`, { json: modifiedParam });
};

export async function previewEmailForInvoice(
  invoiceId: number,
  subject: string,
  body: string,
  sender: Emailable
) {
  return (await ky
    .post(`${baseUrl}/preview/invoices/${invoiceId}`, { json: { subject, body, sender } })
    .json()) as EmailResponse;
}

export async function previewEmailForInvoicesList(
  invoiceIds: number[],
  subject: string,
  body: string,
  sender: Emailable
) {
  return (await ky
    .post(`${baseUrl}/preview/invoices-list/${invoiceIds.join()}`, {
      json: { subject, body, sender },
    })
    .json()) as EmailResponse;
}

export async function previewEmailForCustomer(
  customerId: number,
  subject: string,
  body: string,
  sender: Emailable
) {
  return (await ky
    .post(`${baseUrl}/preview/customers/${customerId}`, { json: { subject, body, sender } })
    .json()) as EmailResponse;
}
