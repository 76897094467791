import { customerNameFormatter } from '@sinecycle/growcomponents';
import { Tooltip } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import { GroupedOptions } from 'components/BaseComponents/ASelect';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { getGroupTemplate } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/NewEmail/util';
import {
  RECIPIENT_OPT_GROUP_KEYS,
  stakeHolderListAttributes,
} from 'components/HigherOrderComponent/KeyActivitesContainer/Email/type';
import {
  chain,
  compact,
  drop,
  filter,
  find,
  forEach,
  get,
  head,
  isArray,
  isEmpty,
  join,
  map,
  omitBy,
  reject,
  some,
  take,
  uniqBy,
} from 'lodash';
import { ActivityKey } from 'queries/activites';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { PTPType } from 'types/activities/promise-to-pay';
import { InvoiceStatus } from 'types/entities/invoice';
import SkeletonLoader from '../FilterComponents/Common/SkeletonLoader';
import { Amount } from '../MultiCurrency';
import {
  disputeTypeListExtract,
  getFormatedRecentUsers,
  getInvoiceGroupType,
  transformToGroupOptions,
} from './Utils';
import { ListOptionsContainer, StyledTag } from './style';
import {
  callLogMessage,
  disputeMessage,
  documentMessages,
  escalationMessage,
  noteMessage,
  ptpMessage,
  taskMessages,
} from './text';
import {
  CallLogFormItemNames,
  DisputeFormItemNames,
  DisputeTypeMapping,
  DocumentFormItemNames,
  EscalationFormItemNames,
  InvoiceFieldMapping,
  MaxTagListRendererProps,
  NoteFormItemNames,
  NotificationMessageProps,
  PTPFormItemNames,
  TagRendererParams,
  TaskFormItemNames,
  TaskRemainderFormNames,
  computedGroupedOptionsProps,
} from './types';

export const createView = {
  activeListLabel: 'Active',
  archivedListLabel: 'Archived',
};

export function invoiceOptionsListLabel(props: {
  invoiceNumber?: string;
  invoiceAmount?: number;
  invoiceCurrency?: string;
  customerName?: string;
}) {
  const { invoiceNumber, invoiceAmount, invoiceCurrency, customerName } = props;
  const name = isArray(customerName) ? head(customerName) : customerName;
  const CustomerNameText = customerNameFormatter({ isUnnamedCustomer: true, name });
  const invoiceNumberAmount = <Amount amount={invoiceAmount} currency={invoiceCurrency} />;
  return (
    <Flex justify="flex-start" align="center">
      <Flex direction="row" gap={'var(--space-48)'}>
        <GrowText ellipsis={{ tooltip: invoiceNumber }} style={{ width: '100px' }}>
          {invoiceNumber}
        </GrowText>
        <GrowText ellipsis={{ tooltip: invoiceNumberAmount }} style={{ width: '150px' }}>
          {invoiceNumberAmount}
        </GrowText>
      </Flex>
      <GrowText
        color={'var(--gray-7)'}
        ellipsis={{ tooltip: CustomerNameText }}
        style={{ width: '100px' }}
      >
        {CustomerNameText}
      </GrowText>
    </Flex>
  );
}

export function generateInvoiceOptions(
  data?: InvoiceFieldMapping[],
  openInvoices?: boolean
): DefaultOptionType[] {
  return chain(data)
    .groupBy((option) => getInvoiceGroupType(option.invoiceStatus))
    .map((options, key) => {
      const optionGroup = find(INVOICE_OPT_GROUPS, { key })?.label;

      return {
        key,
        label: optionGroup,
        title: optionGroup,
        options: generateInvoiceLabel(options),
      };
    })
    .filter(
      (groupObj) => Boolean(groupObj.options.length) && (!openInvoices || groupObj.key === 'OPEN')
    )
    .value();
}

function generateInvoiceLabel(options: InvoiceFieldMapping[]) {
  return map(options, (item) => {
    return {
      label: invoiceOptionsListLabel({
        invoiceNumber: item.invoiceNo,
        invoiceAmount: head(item.invoiceAmount),
        invoiceCurrency: item?.invoiceCurrency,
        customerName: item?.customerName,
      }),
      value: item.id,
    };
  });
}

export function customTagRender<T, K>(
  tagProps: TagRendererParams<T>[number],
  labelKey: keyof K,
  optionsMap?: Map<number, K>
) {
  const { onClose, closable, value, label } = tagProps;
  const labels = optionsMap?.get(value);

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  if (!labels) {
    return (
      <StyledTag>
        <GrowText>{label}</GrowText>
      </StyledTag>
    );
  }

  return (
    <StyledTag onClose={onClose} onMouseDown={onPreventMouseDown} closable={closable}>
      <GrowText>{labels?.[labelKey]}</GrowText>
    </StyledTag>
  );
}

export function ListOptions(
  options: React.ReactElement,
  isSearchLoading?: boolean,
  isEmpty?: boolean,
  Extra?: JSX.Element
) {
  if (isSearchLoading) {
    return (
      <div style={{ padding: 'var(--space-8)' }}>
        <SkeletonLoader size="small" />
      </div>
    );
  }

  const ListJSX = <ListOptionsContainer>{options}</ListOptionsContainer>;

  if (isEmpty && Extra) {
    return <>{Extra}</>;
  }

  return <>{ListJSX}</>;
}

function processUserOptions(
  userOptions: stakeHolderListAttributes[],
  options: GroupedOptions<stakeHolderListAttributes, `${RECIPIENT_OPT_GROUP_KEYS}`>
) {
  const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;
  const transformedUserList = transformToGroupOptions(userOptions);

  const newOptionsToBeAdded = filter(transformedUserList['OTHERS'].options, (transformedOption) => {
    return !some(options['OTHERS'].options, (option) => option.email === transformedOption.email);
  });

  const newGroupOptions = getGroupTemplate();
  const uniqueOptions = uniqBy(newOptionsToBeAdded, 'email');

  forEach(getKeys(newGroupOptions), (key) => {
    if (key === 'OTHERS') {
      newGroupOptions['OTHERS'].options = [...options.OTHERS.options, ...uniqueOptions];
    } else {
      newGroupOptions[key].options = [...options[key].options, ...transformedUserList[key].options];
    }
  });

  return newGroupOptions;
}

export function computedGroupedOptions(
  props: computedGroupedOptionsProps
): BaseOptionType[] | undefined {
  if (!props) return;
  const {
    search = '',
    searchOptionsList,
    activityRecepients,
    searchSelectionTo,
    assigneeUserList,
  } = props;
  let options = search ? searchOptionsList : activityRecepients;

  if (searchSelectionTo && !!searchSelectionTo.length) {
    options = processUserOptions(searchSelectionTo, options);
  }

  if (assigneeUserList && !!assigneeUserList?.length) {
    const usersFormatedList = getFormatedRecentUsers(assigneeUserList);
    options = processUserOptions(usersFormatedList, options);
  }

  return reject(options, (item) => isEmpty(item.options)).map((item) =>
    omitBy(item, (v, k) => k === 'options' && isEmpty(v))
  );
}

export function disputeTypeOptions<T>(
  showActiveList: boolean,
  showArchivedList: boolean,
  activeDisputeTypes?: T[],
  archivedDisputeTypes?: T[]
): DefaultOptionType[] {
  return compact([
    showActiveList && {
      key: 'active',
      label: createView.activeListLabel,
      options: activeDisputeTypes ?? [],
    },
    showArchivedList && {
      key: 'archived',
      label: createView.archivedListLabel,
      options: archivedDisputeTypes ?? [],
    },
  ]);
}

export function MaxTagListRenderer<T>(props: MaxTagListRendererProps<T>) {
  const { list, optionsMap, extractKey } = props;
  const omittedValues = join(
    map(list, (value) => get(optionsMap?.get(value?.value as number), extractKey)),
    ', '
  );

  return (
    <Tooltip
      placement="bottomLeft"
      title={omittedValues}
      overlayInnerStyle={{ maxHeight: '200px', overflow: 'auto' }}
    >
      <GrowText size={'var(--fs-12)'}>{`+${list.length}`}</GrowText>
    </Tooltip>
  );
}

export function AssignOwnerMaxTagListRenderer<T>(
  props: Omit<MaxTagListRendererProps<T>, 'extractKey' | 'optionsMap'>
) {
  const { list } = props;
  const tagList = join(
    map(list, (value) => value?.label),
    ', '
  );

  return (
    <Tooltip
      placement="bottomLeft"
      title={tagList}
      overlayInnerStyle={{ maxHeight: '200px', overflow: 'auto' }}
    >
      <GrowText size={'var(--fs-12)'}>{`+${list.length}`}</GrowText>
    </Tooltip>
  );
}

export const documentOptions = [
  { label: 'Customer', value: 'CUSTOMER' },
  { label: 'Invoice', value: 'INVOICE' },
];

export const documentAssociatedOptions = [
  { label: 'All Open Invoices', value: 'ALL' },
  { label: 'Invoice', value: 'SELECTIVE' },
];

export const PTPTypeOptions = [
  { label: 'Customer Pay Date', value: PTPType.CUSTOMER },
  { label: 'Expected Pay Date', value: PTPType.EXPECTED },
];

export const TaskRemainderFormPayloadSchema: Record<
  string,
  TaskRemainderFormNames[keyof TaskRemainderFormNames]
> = {
  title: 'taskRemainderTitle',
  due_date: 'taskRemainderDueDate',
  participant_config: 'taskRemainderOwner',
};

export const DisputeFormPayloadSchema: Record<
  string,
  DisputeFormItemNames[keyof DisputeFormItemNames]
> = {
  description: 'disputeDescription',
  dispute_type_ids: 'disputeType',
  invoice_ids: 'disputeInvoices',
  invoice_line_item_ids: 'disputeLineItems',
  participant_config: 'disputeOwner',
  title: 'disputeTitle',
  file_upload_ids: 'disputeFileParams',
};

export const CallLogFormPayloadSchema: Record<
  string,
  CallLogFormItemNames[keyof CallLogFormItemNames]
> = {
  call_date: 'callLogDate',
  invoice_ids: 'callLogInvoices',
  description: 'callLogDescription',
  file_upload_ids: 'callLogFileParams',
};

export const NoteFormPayloadSchema: Record<string, NoteFormItemNames[keyof NoteFormItemNames]> = {
  notes: 'noteDescription',
  invoice_line_item_ids: 'noteLineItems',
};

export const EscalationFormPayloadSchema: Record<
  string,
  EscalationFormItemNames[keyof EscalationFormItemNames]
> = {
  description: 'escalationDescription',
  participant_config: 'escalationOwner',
  invoice_ids: 'escalationInvoices',
  invoice_line_item_ids: 'escalationLineItems',
};

export const TaskFormPayloadSchema: Record<string, TaskFormItemNames[keyof TaskFormItemNames]> = {
  description: 'taskDescription',
  title: 'taskTitle',
  invoice_ids: 'taskInvoices',
  participant_config: 'taskOwner',
  due_date: 'taskDate',
};

export const DocumentFormPayloadSchema: Record<
  string,
  DocumentFormItemNames[keyof DocumentFormItemNames]
> = {
  description: 'documentDescription',
  file_upload_ids: 'documentFileParams',
  invoice_ids: 'documentInvoices',
  mapping_type: 'documentMapping',
  type_id: 'documentType',
};

export const PTPFormPayloadSchema: Record<string, PTPFormItemNames[keyof PTPFormItemNames]> = {
  amount_details: 'ptpTable',
  description: 'ptpDescription',
  pay_date: 'ptpDate',
};

export const CLOSED_INVOICE_TYPES: InvoiceStatus[] = [
  InvoiceStatus.CLOSED,
  InvoiceStatus.WRITE_OFF,
  InvoiceStatus.VOID,
];

export const INVOICE_OPT_GROUPS = [
  {
    key: 'OPEN',
    label: 'Open Invoices',
  },
  {
    key: 'CLOSED',
    label: 'Closed Invoices',
  },
];

export function ListDisplay(props: {
  list: number | string;
  index: number;
  total: number[] | string[];
}) {
  const { list, index, total } = props;

  return (
    <div key={index}>
      {list}
      {index < total.length - 1 && ', '}
    </div>
  );
}

export function processList<T>(list: T[]): { initialList: T[]; remainingList: T[] } {
  const initialList = take(list, 3);
  const remainingList = drop(list, 3);
  return { initialList, remainingList };
}

export function isNumberArray(list: any[]): list is number[] {
  return typeof list[0] === 'number';
}

export const ActivityNotificationMessage: Record<ActivityKey, NotificationMessageProps> = {
  CALL_LOG: callLogMessage,
  PROMISE_TO_PAY: ptpMessage,
  NOTE: noteMessage,
  DISPUTE: disputeMessage,
  ESCALATION: escalationMessage,
  TASK: taskMessages,
  DOCUMENT: documentMessages,
};

export function generateDisputeTypeOptions(selectedOptions: DisputeTypeMapping[]) {
  const { activeDisputeTypes, archivedDisputeTypes, showActiveList, showArchivedList } =
    disputeTypeListExtract(selectedOptions);

  return disputeTypeOptions(
    showActiveList,
    showArchivedList,
    activeDisputeTypes,
    archivedDisputeTypes
  );
}
