import { mergeAttributes } from '@tiptap/core';
import { Paragraph } from '@tiptap/extension-paragraph';
import { TransformPastedContent } from '../extensions/default-paste';
import { PlainPastePlugin } from '../extensions/plain-paste';
const ignoredClassName = ['gf-custom-button', 'gf-rte-image-container', 'ispasted'];
export const CustomParagraph = (disableFormatOnPaste?: boolean) => {
  return Paragraph.extend({
    parseHTML() {
      return [
        {
          tag: 'p',
        },
        {
          tag: 'div',
          getAttrs(node) {
            const newNode = node as unknown as HTMLElement;
            if (ignoredClassName.includes(newNode.className)) {
              return false;
            }
            return newNode.children.length ? false : null;
          },
        },
      ];
    },
    renderHTML({ node, HTMLAttributes }) {
      return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
    addAttributes() {
      return {
        style: {
          default: null,
          parseHTML: (element) => ({
            style: element.getAttribute('style'),
          }),

          renderHTML: ({ style }) => style,
        },
        class: {
          default: null,
          parseHTML: (element) => {
            return element.getAttribute('class');
          },
          renderHTML: (HTMLAttributes) => {
            return {
              class: HTMLAttributes.class,
            };
          },
        },
      };
    },
    addProseMirrorPlugins() {
      return disableFormatOnPaste ? [PlainPastePlugin()] : [];
    },
  });
};
