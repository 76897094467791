import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface IconProps extends Partial<React.ComponentProps<typeof FontAwesomeIcon>> {
  style?: React.CSSProperties;
}

export function InfoIconRegular(props: IconProps) {
  return <FontAwesomeIcon color="var(--geekblue-6)" {...props} icon={['far', 'info-circle']} />;
}

export function HelpIconRegular(props: IconProps) {
  return <FontAwesomeIcon icon={['far', 'question-circle']} {...props} color="var(--geekblue-6)" />;
}

export function EllipsisIconRegular(props: IconProps) {
  return (
    <FontAwesomeIcon icon={['far', 'ellipsis-v']} color="var(--primary-7)" style={props.style} />
  );
}
export function SearchIconRegular(props: IconProps) {
  return (
    <FontAwesomeIcon
      icon={['far', 'search']}
      {...props}
      color="var(--gray-7)"
      style={props.style}
    />
  );
}

export function ClearRegular(props: IconProps) {
  return (
    <FontAwesomeIcon
      icon={['far', 'times-circle']}
      {...props}
      color="var(--primary-7)"
      style={props.style}
    />
  );
}
export function ArrowIcon(props: IconProps) {
  return (
    <FontAwesomeIcon
      icon={['far', 'chevron-down']}
      {...props}
      fontSize={12}
      color="var(--gray-6)"
    />
  );
}
export function EditIcon(props: IconProps) {
  return <FontAwesomeIcon icon={['far', 'pen']} {...props} />;
}
