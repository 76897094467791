import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HideWrapper } from '@sinecycle/growcomponents';
import { Button } from 'antd';
import useOpen from 'util/hooks/useOpen';
import {
  ActivityButtons,
  EscalationFormModalProps,
  EscalationModalFormElementProps,
} from '../types';
import EscalationFormModal from './EscalationFormModal';

export function getEscalationConfig(disabled?: boolean, buttonConfig?: ActivityButtons) {
  const iconColor = disabled
    ? undefined
    : buttonConfig?.color
    ? buttonConfig?.color
    : 'var(--primary-7)';

  const icon = <FontAwesomeIcon icon={['far', 'circle-exclamation']} color={iconColor} />;
  return { icon, text: 'Escalate' };
}

export default function EscalationButton<
  EscalationElements extends EscalationModalFormElementProps
>(
  props: Omit<
    EscalationFormModalProps<EscalationElements>,
    'openModal' | 'form' | 'remainder' | 'onClose' | 'onFormClose'
  >
) {
  const { buttonConfig, disabled, mode = 'BUTTON' } = props;
  const { open, toggleOpen, onClose } = useOpen({ open: props.open ?? false });
  const config = getEscalationConfig(disabled, buttonConfig);
  function handleClose() {
    onClose();
    props.handleClose?.();
  }
  return (
    <>
      <HideWrapper hide={mode === 'LIST'}>
        <Button
          icon={!buttonConfig?.hideIcon ? config.icon : null}
          onClick={toggleOpen}
          disabled={disabled}
          {...buttonConfig}
        >
          {!buttonConfig?.hidetext && (buttonConfig?.text || config.text)}
        </Button>
      </HideWrapper>

      <EscalationFormModal<EscalationElements> openModal={open} onClose={handleClose} {...props} />
    </>
  );
}
