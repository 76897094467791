import { EmailSignature } from 'services/signature';
import { SourceApp } from 'types/common/source-app';
import { BusinessUnit } from './business-unit';
import { Region } from './region';
import { Role } from './role';

const gmTokenLevel = ['ACCOUNT', 'USER'] as const;
const gmTokenStatus = ['SUCCESS', 'REVOKED'] as const;
export type GM_TOKEN_LEVEL = (typeof gmTokenLevel)[number];
export type GM_TOKEN_STATUS = (typeof gmTokenStatus)[number];
export type GMS = {
  gmsId: string;
  gmTokenStatus: GM_TOKEN_STATUS;
  gmTokenLevel: GM_TOKEN_LEVEL;
};

export type GMS_DETAILS = {
  [key in GM_TOKEN_LEVEL]: GMS;
};
interface GmsAccount {
  id: number;
  tenantId: number;
  tenantName: string;
  email: string;
  mailServerType: string;
  domain: string;
  syncInterval: string;
  gmTokenStatus: GM_TOKEN_STATUS;
  vaultId: number;
  gms_configs: {
    ar_email: boolean;
  };
}
export interface UserBasic {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  photo_url?: string;
  designation?: string;
  phone?: string;
  timezone?: string;
  hash?: string;
}

export interface User extends UserBasic {
  business_units: BusinessUnit[];
  regions: Region[];
  roles: Role[];
  manager?: UserBasic;
  status: UserStatus;
  deleted: string;
  sender_email: string;
  type?: UserType;
  scope?: {
    business_unit_ids: BusinessUnit[];
    region_ids: Region[];
    subsidiary_ids: number[];
  };
  hash?: string;
  gms_account?: GmsAccount;
  app_type?: SourceApp;
}

export interface CurrentUser extends UserBasic {
  has_team: boolean;
  privileges: string[];
  account_id: number;
  roles: UserRole[];
  sender_email: string;
  email_signature?: EmailSignature | null;
  help_center_auth_token?: string;
  gms_details?: GMS_DETAILS | null;
}

export interface InvoiceFlaggedBy {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  designation: string;
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  DELETED = 'DELETED',
  RESTRICTED = 'RESTRICTED',
}

export enum UserType {
  REGULAR = 'REGULAR',
  RESTRICTED = 'RESTRICTED',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  COLLECTION_HEAD = 'COLLECTION_HEAD',
  COLLECTION_SPECIALIST = 'COLLECTION_SPECIALIST',
  CUSTOMER_POINT_OF_CONTACT = 'CUSTOMER_POINT_OF_CONTACT',
  SALES_MANAGER = 'SALES_MANAGER',
  SCOPED_ADMIN = 'SCOPED_ADMIN',
}

export const UserRoleScope: Record<UserRole, string> = {
  [UserRole.ADMIN]: 'The user has access to the entire data of the organization.',
  [UserRole.COLLECTION_HEAD]:
    'The user has access to all the customers of Collection Specialists for whom the user is marked as Reporting Manager and also to the customers to whom they are mapped as Collection Owner.',
  [UserRole.COLLECTION_SPECIALIST]:
    'The user has access to the customers for whom they are mapped as Collection Owner.',
  [UserRole.SALES_MANAGER]:
    'The user has access to all the customers of Account Managers or Customer Success Managers for whom they are marked as Reporting Manager and also to the customers to whom the User is mapped as Account Manager or Customer Success Manager.',
  [UserRole.CUSTOMER_POINT_OF_CONTACT]:
    'The user has access to the customers for whom they are mapped as Account Manager or Customer Success Manager.',
  [UserRole.SCOPED_ADMIN]:
    'Manage the Collections of a restricted group (Eg: Group of Business Units / Regions) of customers and report on their collection progress and cashflow performance.',
};
